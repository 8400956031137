<template>
  <div class="container">
    <div class="leftBox">
      <div class="title">优化记录</div>
      <div class="recordBox">
        <div
          class="item"
          v-for="(item, index) in recordList"
          :key="index"
          @click="handleClick(index)"
          :class="{ active: recordIndex === index }"
        >
          <div class="textBox">
            <el-input v-model="item.recordName" v-if="itemIndex == index"></el-input>
            <span v-else>{{ item.recordName }}</span>
          </div>
          <div v-if="recordIndex == index">
            <i class="el-icon-edit" @click.stop="handleEdit(index)"></i>
            <i class="el-icon-delete" style="margin-left: 10px"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="rightBox">
      <div class="queryBox">
        <el-form :inline="true" :model="form" label-width="auto">
          <el-form-item label="优化名称">
            <el-select v-model="form.name" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-circle-plus" @click="handleEditFile">编辑文件</el-button>
            <el-button type="warning" icon="el-icon-refresh-right">新建优化</el-button>
            <el-button type="info">保存修改</el-button>
            <img src="../../../assets/optimizeImgs/warn.png" alt />
            <span>双击单元可编辑单元内容</span>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" border style="width: 100%" height="calc(100% - 102px)">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="compName" label="程序文件"></el-table-column>
        <el-table-column label="活动分解">
          <el-table-column label="一级分解"></el-table-column>
          <el-table-column label="二级分解"></el-table-column>
          <el-table-column label="三级分解"></el-table-column>
          <el-table-column label="四级分解"></el-table-column>
          <el-table-column label="五级分解"></el-table-column>
        </el-table-column>
        <el-table-column prop="deptName" label="随机输入（06）" width="130"></el-table-column>
        <el-table-column prop="userName" label="周期"></el-table-column>
        <el-table-column prop="account" label="是否有服务标准" width="130">
          <template slot-scope="scope">
            <el-input v-model="scope.row.operation" placeholder="请双击单元格"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="postName" label="是否补充标准" width="140">
          <template slot-scope="scope">
            <el-input v-model="scope.row.operation" placeholder="请双击单元格"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="是否完成" width="130">
          <template slot-scope="scope">
            <el-input v-model="scope.row.operation" placeholder="请双击单元格"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="jurisdiction" label="历史记录">
          <template slot-scope="scope">
            <el-button type="primary" @click="handleView(scope.row)">查看历史</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 查看历史 -->
    <el-dialog title="历史记录" :visible.sync="dialogVisible" width="1040px">
      <div class="describe">程序文件：采购控制程序</div>
      <div class="tableBox">
        <div class="th">
          <div>
            <div class="line"></div>
          </div>
          <div>修改时间</div>
          <div>修改人</div>
          <div>修改项</div>
          <div>修改前内容</div>
          <div>修改后内容</div>
        </div>
        <div class="td" v-for="(item,index) in historyList" :key="index">
          <div>
            <div class="line">
              <div>
                <div></div>
              </div>
            </div>
          </div>
          <div>{{ item.time }}</div>
          <div>{{ item.name }}</div>
          <div>{{ item.item }}</div>
          <div>{{ item.before }}</div>
          <div>{{ item.after }}</div>
        </div>
      </div>
    </el-dialog>
    <!-- 编辑文件 -->
    <EditFile :visible.sync="dialogFile" title="编辑文件" :fullscreen="true"></EditFile>
  </div>
</template>
  
<script>
import EditFile from "../components/EditFile.vue" 
export default {
  components: {
    EditFile,
  },
  data() {
    return {
      recordList: [
        { recordName: "记录111111111111111111111111" },
        { recordName: "记录" },
        { recordName: "记录" },
      ],
      recordIndex: 0,
      itemIndex: null,
      form: {},
      tableData: [
        {
          compName: "股份公司",
          operation: "",
          jurisdiction: "",
        },
      ],
      page: 1,
      limit: 10,
      total: 0,
      options:[],
      dialogVisible: false,
      historyList: [
        {
          time: "2021/5/26 11:42",
          name: "王大大",
          item: "是否可以取消",
          before: "是",
          after: "否",
        },
        {
          time: "2021/5/26 11:42",
          name: "王大大",
          item: "是否可以取消",
          before: "是",
          after: "否",
        },
        {
          time: "2021/5/26 11:42",
          name: "王大大",
          item: "是否可以取消",
          before: "是",
          after: "否",
        },
        {
          time: "2021/5/26 11:42",
          name: "王大大",
          item: "是否可以取消",
          before: "是",
          after: "否",
        },
      ],
      dialogFile: false,
    };
  },
  methods: {
    handleClick(index) {
      this.recordIndex = index;
      this.itemIndex = null;
    },
    handleEdit(index) {
      this.itemIndex = index;
    },
    // 编辑文件
    handleEditFile(){
      this.dialogFile = true;
    },  
    // 查看历史
    handleView(row) {
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/optimizeTable.less";
.container {
  width: 100%;
  height: 100%;
  display: flex;
  .leftBox {
    width: 272px;
    background: #ffffff;
    border-radius: 2px;
    margin-right: 5px;
    padding: 18px 12px 14px;
    box-sizing: border-box;
    .title {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      margin-bottom: 16px;
    }
    .recordBox {
      height: calc(100% - 46px);
      background: #f8f6f6;
      border: 1px solid #cfcfcf;
      overflow-y: auto;
      .item {
        height: 34px;
        padding: 0 24px 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        .textBox {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 5px;
        }
      }
      .active {
        background: #c1d3ff;
      }
    }
  }
  .rightBox {
    width: calc(100% - 277px);
    background: #ffffff;
    .queryBox {
      padding-left: 22px;
      padding-top: 5px;
      img {
        vertical-align: middle;
        margin-right: 5px;
        margin-left: 8px;
      }
      span {
        vertical-align: middle;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }
      .el-form-item {
        margin-bottom: 6px;
      }
    }
  }
  .describe {
    margin-bottom: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }
  .tableBox {
    width: 100%;
    background: #f8f8f8;
    padding-bottom: 26px;
    .th {
      display: flex;
      height: 52px;
      align-items: center;
      & > div {
        flex: 1;
        text-align: center;
      }
      & > div:nth-child(1) {
        flex: 0 0 52px;
        display: flex;
        justify-content: center;
        .line {
          width: 1px;
          height: 52px;
          background: #dedede;
        }
      }
    }
    .td {
      display: flex;
      height: 52px;
      align-items: center;
      margin-bottom: 10px;
      background-color: #fff;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 14px;
      color: #333333;
      & > div {
        flex: 1;
        text-align: center;
      }
      & > div:nth-child(1) {
        flex: 0 0 52px;
        display: flex;
        justify-content: center;
        background: #f8f8f8;
        .line {
          width: 1px;
          height: 62px;
          background: #dedede;
          position: relative;
          & > div {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            & > div {
              width: 13px;
              height: 13px;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
    & > div:nth-child(3n -1) {
      .line {
        & > div {
          background: #fae4cf;
          border: 1px solid #f4cca6;
          div {
            background: #ff8d28;
            border: 1px solid #e47a1d;
          }
        }
      }
    }
    & > div:nth-child(3n) {
      .line {
        & > div {
          background: #ecf8ad;
          border: 1px solid #dbe6a1;
          div {
            background: #b4d21c;
            border: 1px solid #96af15;
          }
        }
      }
    }
    & > div:nth-child(3n - 2) {
      .line {
        & > div {
          background: #facfcf;
          border: 1px solid #f4a6a6;
          div {
            background: #ff2828;
            border: 1px solid #e41d1d;
          }
        }
      }
    }
  }
}
</style>
